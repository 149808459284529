import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "components/authentication/AuthGuard";
import SidebarLayout from "./layouts/SidebarLayout";
import BaseLayout from "./layouts/BaseLayout";

import SuspenseLoader from "./components/SuspenseLoader";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// session pages
const NotFound = Loader(lazy(() => import("pages/sessions/NotFound")));
const JwtLogin = Loader(lazy(() => import("pages/sessions/JwtLogin")));
// const ComingSoon = Loader(lazy(() => import("pages/sessions/ComingSoon")));

// Pages
const Overview = Loader(lazy(() => import("pages/dashboard/Overview")));

const UsersList = Loader(lazy(() => import("pages/users/UsersList")));

const CollectionsList = Loader(
  lazy(() => import("pages/collections/CollectionsList"))
);

const CollectionDetails = Loader(
  lazy(() => import("pages/collections/CollectionDetails"))
);

const HomeBannersList = Loader(
  lazy(() => import("pages/homeBanners/HomeBannersList"))
);

const HomeBannerDetails = Loader(
  lazy(() => import("pages/homeBanners/HomeBannerDetails"))
);

const CollectionBannersList = Loader(
  lazy(() => import("pages/collBanners/ColBannersList"))
);

const CollectionBannerDetails = Loader(
  lazy(() => import("pages/collBanners/CollBannerDetails"))
);

const AdminProfile = Loader(lazy(() => import("pages/profile/AdminProfile")));
const MarketplaceSettings = Loader(lazy(() => import("pages/marketplace-settings/MarketplaceSettings")));

const routes = [
  {
    path: "",
    element: <BaseLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="dashboard" replace />,
      },
      {
        path: "overview",
        element: <Navigate to="/" replace />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <SidebarLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Overview />,
      },
    ],
  },
  {
    path: "users",
    element: (
      <AuthGuard>
        <SidebarLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <UsersList />,
      },
    ],
  },
  {
    path: "banners",
    element: (
      <AuthGuard>
        <SidebarLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "home/list",
        element: <HomeBannersList />,
      },
      {
        path: "home/details",
        element: <HomeBannerDetails />,
      },
      {
        path: "collection/list",
        element: <CollectionBannersList />,
      },
      {
        path: "collection/details",
        element: <CollectionBannerDetails />,
      },
    ],
  },
  {
    path: "collectionbanners",
    element: (
      <AuthGuard>
        <SidebarLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <CollectionBannersList />,
      },
      {
        path: "details",
        element: <CollectionBannerDetails />,
      },
    ],
  },
  {
    path: "collections",
    element: (
      <AuthGuard>
        <SidebarLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <CollectionsList />,
      },
      {
        path: "details",
        element: <CollectionDetails />,
      },
    ],
  },
  {
    path: "profile",
    element: (
      <AuthGuard>
        <SidebarLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <AdminProfile />,
      },
    ],
  },
  {
    path: "marketplace-settings",
    element: (
      <AuthGuard>
        <SidebarLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <MarketplaceSettings />,
      },
    ],
  },
  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },

  { path: "/", element: <Navigate to="dashboard" /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
